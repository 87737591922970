import React, { useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import { Typography } from "@material-ui/core"

// Utils
import { useStore } from "store"
import getParameter from "utils/getParameter"
import * as theme from "theme"

// Components
import BackgroundImage from "../../../components/backgroundImage"
import LinkBoxes from "../../../components/footer/LinkBoxes"
import SocialIcons from "../../../components/footer/Socials"

// Assets
import PlayIcon from "../../../../static/images/icons/play-icon.svg"
import MastheadIcon from "../../../assets/svg/Masthead.svg"
import MarkIcon from "../../../assets/svg/Mark.svg"

export default function ColinJustinTemplate(props) {
  const {
    data: {
      wp: {
        themeSettings: {
          siteOptions: { notifications }
        }
      },
      page: {
        templateColinJustin: { backgroundImage, showNotifications, hideFooterLinks, overlayImage, videos }
      }
    }
  } = props

  const key = getParameter("cjv")

  const notification = notifications.find((o) => o.languageCode === process.env.GATSBY_ACTIVE_LANGUAGE)

  useEffect(() => {
    if (key) {
      const index = parseInt(key)

      if (videos[index]) {
        // We have to delay the trigger a bit to make if work
        setTimeout(() => showLightBox(index), 100)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])

  useEffect(() => {}, [])

  const dispatch = useStore()[1]

  const showLightBox = (index) => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        open: true,
        slide: index,
        slides: videos,
        options: {
          thumbs: false
        }
      }
    })
  }

  return <>
    <Container>
      <Hero>
        <HeroContent>
          <MastheadIcon />
          <MarkIcon />
        </HeroContent>
        <HeroImageContainer>
          {backgroundImage && <BackgroundImage image={backgroundImage} />}

          <OverlayImageContainer>
            {overlayImage?.localFile?.childImageSharp?.gatsbyImageData && (
              <GatsbyImage image={overlayImage.localFile.childImageSharp.gatsbyImageData} />
            )}
          </OverlayImageContainer>
        </HeroImageContainer>
      </Hero>

      <Videos>
        {videos &&
          videos.map((o, i) => {
            return (
              <Video key={i} className="video">
                <VideoImageContainer className="videoImage" onClick={() => showLightBox(i)}>
                  {o.image && <BackgroundImage image={o.image} />}

                  <IconContainer className="playIcon">
                    <PlayIcon />
                  </IconContainer>
                </VideoImageContainer>

                <VideoContent>
                  <Title variant="h3" children={o.title} />
                  <Subtitle variant="caption" children={o.subtitle} className="subtitle" />
                  <Caption variant="caption" children={o.caption} />
                </VideoContent>
              </Video>
            )
          })}

        <CallToAction>
          <CallToActionHeadline variant="h4" children="Follow us for more Western Red Cedar inspiration" />

          <SocialMediaIcons>
            <SocialIcons />
          </SocialMediaIcons>
        </CallToAction>
      </Videos>
    </Container>

    {notification && showNotifications && (
      <>
        {showNotifications.includes("metrics") && <Notification children={notification.noteForMetrics} />}
        {showNotifications.includes("standards") && (
          <Notification children={notification.noteForNorthAmericanStandards} />
        )}
      </>
    )}

    {hideFooterLinks !== true && <LinkBoxes />}
  </>;
}

const Container = styled.div``

const Notification = styled(Typography)`
  width: 100%;
  margin: 30px 0;
  padding: 20px;
  border: 1px solid ${theme.colors.secondary};
`

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fbfbf1;
  min-height: 420px;
  overflow: hidden;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`

const HeroContent = styled.div`
  padding-top: 60px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 800px) {
    width: calc((100% / 2) + 5px);
    padding-top: 180px;
    padding-left: 100px;
    padding-right: 80px;
  }
  @media (min-width: 1120px) {
    width: calc((100% / 3) + 4px);
  }
`

const HeroImageContainer = styled.div`
  position: relative;

  @media (max-width: 799px) {
    height: 400px;
  }

  @media (min-width: 800px) {
    width: calc(100% - (100% / 2));
  }
  @media (min-width: 1120px) {
    width: calc(100% - (100% / 3));
  }
`

const OverlayImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: -100px;

  @media (min-width: 500px) {
    left: -30px;
  }

  .gatsby-image-wrapper {
    position: unset !important;
    max-width: 100%;
  }
`

const Videos = styled.div`
  margin: 5px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .video {
    &:nth-of-type(1) {
      .playIcon,
      .subtitle {
        background-color: #ff8156;
      }
    }
    &:nth-of-type(2) {
      .playIcon,
      .subtitle {
        background-color: #aaf684;
      }
    }
    &:nth-of-type(3) {
      .playIcon,
      .subtitle {
        background-color: #ffdf9a;
      }
    }
    &:nth-of-type(4) {
      .playIcon,
      .subtitle {
        background-color: #ffb389;
      }
    }
    &:nth-of-type(5) {
      .playIcon,
      .subtitle {
        background-color: #4eddce;
      }
    }
  }
`

const Video = styled.div`
  width: 100%;
  margin-bottom: 5px;
  cursor: pointer;

  @media (min-width: 800px) {
    width: calc((100% / 2) - 3px);
  }
  @media (min-width: 1120px) {
    width: calc((100% / 3) - 3px);
  }

  &:hover {
    .videoImage {
      &:before {
        opacity: 0;
      }

      .playIcon {
        transform: scale(1.05);
      }
    }
  }
`

const VideoImageContainer = styled.div`
  position: relative;
  height: 260px;
  width: 100%;

  &:before {
    transition: opacity 0.15s ease-in-out;
    content: "";
    background: rgba(255, 255, 255, 0.17);
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
  }
`

const IconContainer = styled.div`
  transition: transform 0.15s ease-in-out;
  position: absolute;
  left: calc(50% - (62px / 2));
  top: calc(50% - (62px / 2));
  width: 62px;
  height: 62px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  svg {
    margin-left: 8px;

    g {
      fill: #000;
    }
  }
`

const VideoContent = styled.div`
  margin-top: -7px;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 20px;
`

const Title = styled(Typography)`
  margin-bottom: 6px;
`

const Subtitle = styled(Typography)`
  display: inline-block;
  padding: 8px 10px 6px;
  font-style: italic;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #363837;
  margin-bottom: 20px;
`

const Caption = styled(Typography)`
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #343434;
`

const CallToAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fbfbf1;
  padding: 53px;
  width: 100%;
  margin-bottom: 5px;
  cursor: pointer;

  @media (min-width: 800px) {
    width: calc((100% / 2) - 3px);
  }
  @media (min-width: 1120px) {
    width: calc((100% / 3) - 3px);
  }
`

const CallToActionHeadline = styled(Typography)`
  text-align: center;
  margin-bottom: 20px;
`

const SocialMediaIcons = styled.div`
  display: flex;

  svg {
    margin: 0 5px;
    padding: 8px;
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 50%;

    path {
      fill: #000;
    }
  }
`

export const CollectionQuery = graphql`query ColinJustinTemplate($id: String!) {
  wp {
    themeSettings {
      siteOptions {
        notifications {
          languageCode
          noteForMetrics
          noteForNorthAmericanStandards
        }
        sectionHeroImage {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          path
        }
      }
    }
  }
  page: wpPage(id: {eq: $id}) {
    templateColinJustin {
      backgroundImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      overlayImage {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, layout: FIXED)
          }
        }
      }
      videos {
        title
        subtitle
        link {
          url
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 500, layout: CONSTRAINED)
            }
          }
        }
        caption
      }
      cjHideSearch
    }
  }
}
`
